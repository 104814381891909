<template>
  <b-modal
      ref="transfer-modal" hide-footer
      :title="`Transfer Domain`"
  >
    <div class="row">
      <div class="col">
        <input type="text" class="form-control border" placeholder="Domain" v-model="domain.domain_name">
        <span v-if="$v.domain.domain_name.$error" class="text-danger">Domain name is required</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <input type="text" class="form-control border" placeholder="Transfer Code" v-model="domain.transfer_code">
        <span v-if="$v.domain.transfer_code.$error" class="text-danger">Transfer code is required</span>
      </div>
    </div>
    <div class="col-12 text-right mt-4">
      <b-button size="sm" class="'btn-secondary text-black" @click="hideModal('dns-manage')">Cancel</b-button>
      <b-button class="ml-2 btn btn-primary"  size="sm" @click="saveTransferDomain()" :disabled="is_already_exist">
        Save
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  name: "transfer-domain",
  validations: {
    domain: {
      domain_name: {required},
      transfer_code: {required}
    }
  },
  data() {
    return {
      domain_name: null,
      is_already_exist: false,
      domain: {
        domain_name: null,
        transfer_code: null
      }
    }
  }, methods: {
    showModal() {
      this.$refs['transfer-modal'].show()
    },
    hideModal() {
      this.$refs['transfer-modal'].hide()
    }, saveTransferDomain() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        },3000)
      }
    }
  }
}
</script>

<style scoped>

</style>
