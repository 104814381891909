<template>
  <div class="row">
    <div class="col-md-12">
      <h3>Domains</h3>
    </div>
    <div class="col-md-12 justify-end text-right">
      <v-btn dark rounded color="blue lighten-2" class="mx-2" depressed>
        <a :href="registerDomainUrl" class="text-white" target="_blank">
          Register new domain
        </a>
      </v-btn>
    </div>
    <div class="col-xl-12">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
              Registered domains
            </h3>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="row" v-if="registeredDomainLoading==true">
            <div class="col-md-12">
              <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
            </div>
          </div>
          <div v-else>
            <div class="row" v-if="newOrTransferedDomains && newOrTransferedDomains.length>0">
              <div class="col-md-12 col-lg-12">
                <table class="table">
                  <thead>
                  <tr>
                    <th>Domain Name</th>
                    <th>Expiry Date</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(domain, index) in newOrTransferedDomains" :key="index">
                    <td>{{ domain.domain_name }}</td>
                    <td>{{ domain.formatted_expiry_date }}</td>
                    <td>
                      <router-link
                          :to="{ name: 'dns-management', params: { domain_name:domain.domain_name }}"
                      >Managed DNS
                      </router-link>
                      <a href="#" @click.prevent="availabilityCheck(domain.domain_name)">| Renew</a>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <!--                            <div class="col-2 ml-auto">-->
                <!--                                <button class=" btn btn-success" @click="openTransferDomain()">Transfer Domain</button>-->
                <!--                            </div>-->
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-md-12 text-center">
                <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                <h5 class="mt-10">Looks like you have not registered any domain. Register your domain today.</h5>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div class="col-xl-12">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
              Zone editor (DNS)

            </h3>
            <a href="#" class="pull-right badge badge-success pull-right ml-5" @click="openManageDns">
              <i class="fas fa-plus"></i> Add domain
            </a>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="row" v-if="zoneEditorLoading==true">
            <div class="col-md-12">
              <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
            </div>
          </div>
          <div v-else>
            <div class="row" v-if="managed_dns && managed_dns.length>0">
              <div class="col-md-12 col-lg-12">
                <table class="table">
                  <thead>
                  <tr>
                    <th>Domain</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(dns, index) in managed_dns" :key="index">
                    <td>{{ dns.domain_name }}</td>
                    <td>
                      <i
                          :class="(dns.is_verified)?'fas fa-check text-success':'fas fa-ban text-danger'"
                      ></i>
                      <span v-if="dns.is_verified"> Verified</span>
                      <span v-else> Pending verification</span>

                    </td>
                    <td>
                      <router-link v-if="dns.is_verified" class=""
                                   :to="{ name: 'dns-management', params: { domain_name:dns.domain_name }}"
                      >Manage Zone
                      </router-link>
                      <span v-if="!dns.is_verified && is_verified "> | </span>
                      <router-link v-if="!dns.is_verified"
                                   :to="{ name: 'domain-verfication', params: { domain_name:dns.domain_name }}"
                      > Complete Verification
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-md-12 text-center">
                <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                <h5 class="mt-10">Looks like you have not added any domain to manage. Add domain to manage DNS, it's free.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
        ref="my-modal" hide-footer
        :title="`Renew domain ${domain_name}`"
    >
      <div class="row">
        <div class="col text-center">
          <h4>
            {{ `${response.currency} ${response.price}` }}
          </h4>
          <br>
          <div v-if="!exist_in_cart">
            <v-btn :loading="loading" class="btn btn-success" @click="buildCartObject">Add to cart</v-btn>
          </div>
          <div v-else class="text text-danger">
            Domain is already added to cart. Please visit cart for further processing.
          </div>
        </div>
      </div>
      <div class="col-12 text-right mt-4">
        <b-button size="sm" class @click="hideModal('my-modal')">Cancel</b-button>
      </div>
    </b-modal>
    <b-modal
        ref="dns-manage" hide-footer
        :title="`Manage DNS`"
    >
      <div class="row">
        <div class="col text-center">
          <input type="text" class="form-control border" v-model="domain_name" @input="validateDomain"
                 placeholder="Enter domain name">
          <!--          <p class="text-danger" v-if="domain_name && is_already_verified">Domain that you have entered either already-->
          <!--            exist</p>-->
          <span class="" v-if="domain_name && is_already_exist">
          <p class="text-danger" v-if="!is_already_verified">Domain verification required</p>
            <p class="text-danger" v-else>Not a valid domain</p>
          </span>
        </div>
      </div>
      <div class="col-12 text-right mt-4">
        <a class="btn btn-secondary mr-2" @click="hideModal('dns-manage')">Cancel</a>
        <b-button v-if="!is_already_exist" class="btn btn-primary text-white" @click="saveDomainForVerification"
                  :disabled="is_already_exist">
          Add
        </b-button>
        <b-button class="btn btn-primary text-white ml-2" @click="redirectToVerifyDomain"
                  v-if="is_already_exist && !is_already_verified">
          Continue Verification
        </b-button>
      </div>
    </b-modal>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <transfer-domain ref="transfer-domain" @close_transfer_domain="closeTransferDomain()"></transfer-domain>
  </div>
</template>


<script>
import {mapActions, mapState} from "vuex";
import Website from "@/services/Websites/WebsiteService";
import DomainService from "@/services/domain/DomainService";
import DomainVerificationService from "@/services/domain/DomainVerificationService";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";
import {validationMixin} from "vuelidate";
import {minLength, required} from "vuelidate/lib/validators";
import RegisterDomain from "@/views/domain/RegisterDomain";
import CartService from "@/services/cart/CartService";
import TransferDomain from "./Modal/TransferDomain"

const cartService = new CartService();
const WebsiteService = new Website();
const domainService = new DomainService();
const verificationService = new DomainVerificationService();


export default {
  name: "website-getting-started",
  components: {
    RegisterDomain, TransferDomain
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        freeDomain: "",
        oldDomain: "",
        newDomain: ""
      },
      registeredDomainLoading: true,
      zoneEditorLoading: true,
      domains: [],
      domain_name: null,
      exist_in_cart: false,
      overlay: false,
      loading: false,
      managed_dns: [],
      response: {},
      is_already_verified: true,
      is_already_exist: false,
      createWebsiteUrl: "",
      registerDomainUrl: "",
    };
  },
  validations: {
    isUniqueDomain: false,
    form: {
      newDomain: {
        required,
        minLength: minLength(3),
        async isUnique(value) {
          if (value === "") return true;
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              ApiService.post("user/website/validate-domain", {
                title: this.$v.form.newDomain.$model
              }).then(response => {
                resolve(response.data.status);
              });
            }, 100);
          });
        }
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    newOrTransferedDomains() {
      return this.domains.filter(domain => {
        if (domain.registration_type && domain.registration_type == 'new' || domain.registration_type == 'transfer')
          return domain;
      })
    }
  },
  methods: {
    ...mapActions(["ADD_TO_CART"]),
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        newDomain: null
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    newDomain() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const newDomain = this.$v.form.newDomain.$model;

      let website = {
        title: newDomain,
        user_id: 1,
        is_custom_domain: 1,
        is_active: 1
      };
      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit_free");
      submitButton.classList.add("kt-spinner");

      // dummy delay
      setTimeout(() => {
        WebsiteService.create(website)
            .then(res => {

              this.$router.push({
                name: "website-created",
                params: {domainname: res.data.website.title}
              });
              submitButton.classList.remove("kt-spinner");
            })
            .errors(err => {

              submitButton.classList.remove("kt-spinner");
            });
      }, 2000);
    },
    getAllDomain() {
      this.registeredDomainLoading = true
      domainService
          .paginate()
          .then(response => {
            this.domains = response.data.data;
            this.registeredDomainLoading = false
          })
          .catch(error => {
            this.registeredDomainLoading = false
          });
    },
    getAllManagedDns() {
      this.zoneEditorLoading = true;
      verificationService
          .getByUser()
          .then(response => {
            this.managed_dns = response.data;
            this.zoneEditorLoading = false;
          })
          .catch(error => {
            this.zoneEditorLoading = false;
          });
    },
    showModal() {
      this.checkDomainInCart();
      this.$refs['my-modal'].show()
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
    availabilityCheck(domainName) {
      this.domain_name = domainName
      this.overlay = true;
      domainService
          .checkAvailabiltiy(this.domain_name)
          .then(response => {
            this.overlay = false;
            this.response = response.data;
            if (this.response.status === "yes") {
              this.showModal()
            }
          })
          .catch(error => {
          });
    },
    checkDomainInCart() {
      cartService
          .checkDomainInCart(this.domain_name)
          .then(response => {
            if (response.data) {
              this.exist_in_cart = true;
            } else {
              this.exist_in_cart = false
            }
          })
          .catch(error => {
          });
    },
    buildCartObject() {
      this.loading = true
      this.cart = {
        item_type: "domain",
        domain_name: this.response.domain_name,
        purchase_type: "renew",
        terms: 1 * 12,
        item_price: this.response.price,
        is_processed: 0
      };
      this.addToCart();
    },
    addToCart() {
      if (this.cart) {
        this.ADD_TO_CART(this.cart)
            .then(response => {
              setTimeout(() => {
                this.$snotify.success("Added to cart");
                this.loading = false
                this.checkDomainInCart();
              }, 3000);
            })
            .catch(error => {
              this.$snotify.error("Error ocured");
            });
      }
    }, openManageDns() {
      this.$refs['dns-manage'].show();
    },
    validateDomain() {
      this.show_verification_button = false
      verificationService.validateDomain(this.domain_name).then(response => {
        this.is_already_exist = response.data.status
        if (this.is_already_exist) {
          if (response.data.msg == "Verification Required") {
            this.is_already_verified = false
          } else {
            this.is_already_verified = true
          }
        }
      })
    },
    saveDomainForVerification() {
      let data = {
        domain_name: this.domain_name,
        is_active: 1,
        verification_method: "nameserver",
        domain_hosting_only: 1
      }
      verificationService.store(data).then(response => {
        this.$snotify.success("Domain added for verification");
        this.$router.push({name: "domain-verfication", params: {domain_name: this.domain_name}})
        this.getAllManagedDns();
      })
    }, redirectToVerifyDomain() {
      this.$router.push({name: "domain-verfication", params: {domain_name: this.domain_name}})
    }, openTransferDomain() {
      this.$refs['transfer-domain'].showModal();
    }, closeTransferDomain() {
      this.$refs['transfer-domain'].hideModal();
    }
  },
  mounted() {
    this.createWebsiteUrl = process.env.VUE_APP_SERVER_URL + 'templates';
    this.registerDomainUrl = process.env.VUE_APP_SERVER_URL + "services/domain";
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "New Website", route: "dashboard"}
    ]);
  },
  created() {
    this.getAllDomain();
    this.getAllManagedDns();
  }
};
</script>
<style scoped>
.card-header a {
  font-size: 1.25em;
  text-align: left;
}
</style>
